import React from 'react'
import { graphql } from 'gatsby'
import { PodcastEpisodePage } from '@/components'

// Todo: Pass podcast context/data here.
const PodcastEpisode = props => <PodcastEpisodePage {...props} />

export default PodcastEpisode

export const PodcastPageQuery = graphql`
  query SinglePodcastQuery($episode: String!, $serie: String!) {
    allPrismicPodcastepisode(
      filter: {
        data: { serie: { uid: { eq: $serie } } }
        lang: { eq: "en-us" }
      }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            disable_link
            sort_date
          }
        }
      }
    }
    prismicPodcastepisode(uid: { eq: $episode }, lang: { eq: "en-us" }) {
      uid
      data {
        title {
          text
        }
        sort_date
        transcript {
          html
        }
        transcript_short {
          html
        }
        summary {
          html
          text
        }
        serie {
          uid
        }
        participants {
          name {
            text
          }
          image {
            url
          }
          bio {
            html
          }
        }
        episode_links {
          link_title {
            text
          }
          link_image {
            alt
            url
          }
          link_description {
            html
          }
          url {
            url
          }
        }
        highlights {
          highlight {
            text
          }
          highlight_source {
            text
          }
          highlight_time
        }
        video {
          embed_url
          title
        }
        soundcloud_embed {
          embed_url
          title
        }
        social_image {
          url
        }
        episode
        collapse_transcript
      }
    }
  }
`
